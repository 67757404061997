<template>
    <div>
        <v-text-field v-if="type==2 || type=='string'" 
            outlined dense text hide-details
            v-model="valueToShow" :label="label" 
            :readonly="readonly" 
            @input="emitInput" @change="emitChange"
        ></v-text-field>
        <v-text-field v-else-if="type=='number'" 
            type="number"
            outlined dense text hide-details
            v-model="valueToShow" :label="label" 
            :readonly="readonly" 
            @input="emitInput" @change="emitChange"
        ></v-text-field>
        <v-checkbox v-else-if="type=='boolean'"
            v-ripple
            v-model="valueToShow" :label="label"
            :readonly="readonly"
            @change="emitInput(); emitChange();">
        </v-checkbox>
        <v-select v-else-if="type=='list'"
            outlined dense hide-details
            v-model="valueToShow" :label="label"
            :items="options"
            :item-value="listValue" :item-text="listText"
            :readonly="readonly" :clearable="!readonly"
            :menu-props="{ offsetY: true }"
            @input="emitInput" @change="emitChange">
        </v-select>
        <span v-else>Tipo no disponible</span>
    </div>
</template>

<script>

export default ({
    props: {
        // Common props
        value: {},
        label: { type: String, default: '' },
        type: { type: [Number, String], default: 2 },
        // Control props
        readonly: { type: Boolean, default: false},
        // List props
        listValue: { type: String, default: 'value' },
        listText: { type: String, default: 'text' },
        options: { type: Array, default: () => [] }
    },
    watch: {
        value: {
            immediate: true,
            handler () {
                this.valueToShow = this.value
            }
        }
    },
    data () {
        return {
            valueToShow: ''
        }
    },
    methods: {
        // Emit Events
        emitInput()
        {
            this.$emit('input', this.valueToShow)
        },
        emitChange()
        {
            this.$emit('change', this.valueToShow)
        }
    }
})
</script>
