<template>
    <div>
        <v-container>
          <v-card style="background-color: #F5F5F5" elevation="3">
            <div class="mt-2 mb-5 text-h5 text-sm-h5 text-md-h4 text-lg-h4 ma-3 text-xl-h4 text-center">
              {{pregunta.Descripcion}}
            </div>
            
            <v-form
              ref="form"
              v-model="isValid"
              autocomplete="off"
            >
                <!-- FORMULARIO PREGUNTA -->
                <template v-if="pregunta != null && pregunta.Lineas != null">
                  <div v-for="(item, index) in pregunta.Lineas" :key="index" class="my-4 px-4">
                    <h4>{{item.Texto}}</h4>
                    <span>{{item.Descripcion}}</span>
                    <div class="my-2" v-if="item.Observaciones">
                      <v-text-field v-model="item.Respuesta.Respuesta" :label="item.Texto" type="number" min="0" step="1"></v-text-field>
                    </div>
                    <div class="my-2" v-if="!item.Observaciones">
                      <DynamicInput v-model="item.Respuesta.Respuesta" :label="item.Texto"></DynamicInput>
                    </div>
                    <hr/>
                  </div>
                </template>


            <!-- ACEPTAR / CANCELAR / BORRAR -->
            <v-footer app bottom fixed padless>
              <v-row class="my-1" justify="center">
                  <v-btn
                      :loading="loading"
                      :disabled="!isValid"
                      @click="onBtnSave"
                      color="primary"
                      class="mt-2 mb-4 mr-2"
                  >
                      Enviar respuestas
                  </v-btn>
                  
                  <v-btn
                      class="mt-2 mb-4 mr-2"
                      style="width:100px"
                      @click="goToList"
                  >
                      Cancelar
                  </v-btn>
              </v-row>
            </v-footer>
            </v-form>
          </v-card>
        </v-container>
    </div>
</template>

<script>
import axios from "axios";
import { mapState } from "vuex";
import DynamicInput from "../../components/DynamicInput.vue";

export default ({
    components: {
      DynamicInput
    },
    computed: {
        ...mapState(['empId', 'dirId', 'perId', 'perIdAux', 'esTienda', 'urlRaiz']),
        perIdToSend: function () {
          if (this.perIdAux == null || this.perIdAux == 0) return this.perId;
          return this.perId;
        },
    },
    data () {
        return {
            loading: false,
            isValid: false,
            test: '',
            pregunta: {
                PreguntaId: 0,
                DirValue: []
            }
        }
    },
    // watch: {
      // test: {
      //   handler () {
      //       this.validate();
      //   },
      //   deep: true,
      // },
    // },
    mounted() {
        if (!this.esTienda) { this.$router.push('/'); return; }
      
        this.pregunta.PreguntaId = this.$route.params.id;
        this.loadData();
    },
    methods: {
      goToList() {
        this.$router.push('/preguntas');
      },

      //#region Llamadas a la API
      getDataFromApi() {
        this.loading = true;
        return new Promise((resolve) => {
          const controllerParameters = {
            PreguntaId: this.pregunta.PreguntaId,
            DirId: this.dirId
          };       
          const AuthToken = localStorage.getItem('token');
          axios({ method: "POST", "url": this.urlRaiz + "/api/preguntas/get-response", "data": JSON.stringify(controllerParameters), "headers": {"content-type": "application/json", "Authorization": AuthToken } })
          .then(result => {
            const item = result.data.Pregunta;
            const respuestas = result.data.Respuestas;

            setTimeout(() => {
                this.loading = false;
                resolve({
                  item, respuestas
                })
            }, 1000)
          })
          .catch(error => {
            if(error != null) {
                this.loading = false;
                alert('Error al cargar la pregunta.');
                this.goToList();
            }
          });
        })
      },
      postDataToApi() {
        this.loading = true;

        return new Promise((resolve) => {
          const controllerParameters = {
            PreguntaId: this.pregunta.PreguntaId,
            DirId: this.dirId,
            PerId: this.perIdToSend,
            Respuestas: this.pregunta.Lineas.map(m => m.Respuesta)
          };       
          const AuthToken = localStorage.getItem('token');
          axios({ method: "POST", "url": this.urlRaiz + "/api/preguntas/save-response", "data": JSON.stringify(controllerParameters), "headers": {"content-type": "application/json", "Authorization": AuthToken } })
          .then(result => {
            const item = result.data;

            setTimeout(() => {
                this.loading = false;
                resolve({
                  item
                })
            }, 1000)
          })
          .catch(error => {
            if(error != null) {
                this.loading = false;
                alert('Error al guardar la pregunta.');
            }
          });
        })
      },
      //#endregion

      //#region Carga de datos
      loadData() {
        this.getDataFromApi()
          .then(data => {
            if(data.item == null) {
              alert('Pregunta no encontrada.');
              this.goToList();
              return;
            }

            this.pregunta = data.item;
            this.pregunta.Lineas.forEach(f => {
              const response = data.respuestas.find(i => f.PreguntasLineaId == i.PreguntasLineaId);
              f.Respuesta = response != null ? response : { PreguntaId: f.PreguntaId, PreguntasLineaId: f.PreguntasLineaId };
            })
          });
      },
      //#endregion

      //#region Click guardado
      onBtnSave() {
          this.postDataToApi().then(data => {
            if(data.item.PreguntaId != null) {
                this.goToList();
            } else {
              if (data.item != '') {
                alert(data.item);
              } else {
                alert('Error al guardar la pregunta.');
              }
            }
          });
      },
      //#endregion
    }
})
</script>

<style>
  .perConDt tr.edit-row > td{
    padding-left: 4px !important;
    padding-right: 4px !important;
  }
</style>
